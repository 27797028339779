import {
  createApp, nextTick
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import PlayerModal from './components/PlayerModal.vue';
import BillboardModal from './components/BillboardModal.vue';
import VueGtag from 'vue-gtag';

import {
  eventBus
} from './plugins/event-bus'; // Stellen Sie sicher, dass der Pfad korrekt ist
import {
  loadFonts
} from './plugins/webfontloader'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import BootstrapVueNext from 'bootstrap-vue-next'

import {
  use
} from "echarts/core"
import {
  CanvasRenderer
} from 'echarts/renderers'

import './registerServiceWorker';

use([
  CanvasRenderer,
]);



loadFonts()

router.beforeEach((to, from, next) => {
  let title = 'Handball Statistiken'; // Standardtitel

  // Eine Hilfsfunktion, um Bereiche zu verarbeiten und den Titel festzulegen.
  const processBereicheAndSetTitle = () => {
    if (to.params.bundesland !== from.params.bundesland && store.state.ligenJSON && to.params.bundesland) {
      let bereiche = store.state.ligenJSON[to.params.bundesland];
      bereiche = Object.keys(bereiche).map(name => {
        return {
          name: name,
          ligen: bereiche[name].Ligen,
          order: bereiche[name].order,
          display_name: bereiche[name].display_name
        };
      }).sort((a, b) => a.order - b.order);
      store.commit('setBereiche', bereiche);
    }

    if (to.meta.title) {
      title = to.meta.title;

      title = title.replace(':bundesland', to.params.bundesland || '');
      title = title.replace(':season', to.params.season || '');
    }
    document.title = title;
    next();
  };

  if (!store.state.ligenJSON && to.params.season) {
    const filePath = `${router.options.base}cache/ligen.json`;
    fetch(filePath)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        store.commit('setLigenJSON', data);
        processBereicheAndSetTitle();
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        next(error);
      });
  } else {
    processBereicheAndSetTitle();
  }
});

router.afterEach((to) => {
  const mainElement = document.querySelector('.v-main');
  if (mainElement) {
    mainElement.scrollTo(0, 0);
  }
  nextTick(() => {
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: to.fullPath,
        page_path: to.fullPath
      });
    }
  })
})

const app = createApp(App);

app.config.globalProperties.$playerModal = function (playerData) {
  const modalInstance = createApp(PlayerModal, {
    player: playerData
  });

  const mountNode = document.createElement('div');
  document.body.appendChild(mountNode);
  modalInstance.use(store).use(router).use(BootstrapVueNext).use(vuetify);
  modalInstance.mount(mountNode);
}

app.config.globalProperties.$billboardModal = function (players, chartType) {
  let modalInstance = null;

  const closeHandler = () => {
    if (modalInstance) {
      modalInstance.unmount();
      modalInstance = null;
    }
  };

  if (!modalInstance) {
    modalInstance = createApp(BillboardModal, {
      players: players,
      chartType: chartType,
      onClose: closeHandler // Übergeben Sie die closeHandler-Methode als Prop
    });

    const mountNode = document.createElement('div');
    document.body.appendChild(mountNode);
    modalInstance.use(store).use(router).use(BootstrapVueNext).use(vuetify);
    modalInstance.mount(mountNode);
  }
}

app.use(store).use(router).use(vuetify).use(BootstrapVueNext).mount('#app');

const consentGiven = localStorage.getItem('ga_consent');
if (consentGiven === 'true') {
  app.use(VueGtag, {
    config: {
      id: 'G-J24364M7SP'
    },
    appName: 'Handball Statistiken',
    pageTrackerScreenviewEnabled: false, // Stellen Sie sicher, dass dies auf false gesetzt ist
    pageTrackerEnabled: false // Deaktiviert die automatische Seitenansichtsverfolgung
  }, router);
}
app.config.globalProperties.$eventBus = eventBus; // Jetzt ist $eventBus in allen Komponenten verfügbar