const DB_NAME = "handball";
const DB_VERSION = 6; // Erhöhen Sie die Versionsnummer, um das onupgradeneeded Event auszulösen

let db;
let dbInitPromise = new Promise((resolve, reject) => {

    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = (event) => {
        console.error("Error opening database:", event.target.errorCode);
        reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
        db = event.target.result;
        resolve();
    };

    request.onupgradeneeded = (event) => {
        db = event.target.result;

        // Löschen der vorhandenen ObjectStores, falls erforderlich
        if (db.objectStoreNames.contains("leagues")) {
            db.deleteObjectStore("leagues");
        }
        if (db.objectStoreNames.contains("metadata")) {
            db.deleteObjectStore("metadata");
        }
        if (db.objectStoreNames.contains("favorites")) {
            db.deleteObjectStore("favorites");
        }

        // Erneutes Erstellen der ObjectStores
        db.createObjectStore("leagues", {
            keyPath: "id",
            autoIncrement: true
        });
        db.createObjectStore("metadata", {
            keyPath: "id"
        });
        db.createObjectStore("favorites", {
            keyPath: "id",
            autoIncrement: true
        });
    };
});

// Funktion zum Hinzufügen oder Aktualisieren von Metadaten
export const updateMetadata = (key, timestamp) => {
    return dbInitPromise.then(() => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["metadata"], "readwrite");
            const store = transaction.objectStore("metadata");
            const dataToStore = {
                id: key,
                timestamp: timestamp
            };
            const request = store.put(dataToStore);
            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event.target.errorCode);
        });
    });
};

// Funktion zum Abrufen von Metadaten
export const getMetadata = (key) => {
    return dbInitPromise.then(() => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["metadata"], "readonly");
            const store = transaction.objectStore("metadata");
            const request = store.get(key);
            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event.target.errorCode);
        });
    });
};

export const addData = async (key, players, league_stats) => {
    await dbInitPromise;

    // Zuerst die Gesamtzahl der Ligen ermitteln
    const leagues = await getAllData();
    if (leagues.length >= 15) {
        // Finde die Liga mit dem ältesten Zugriff
        let oldestKey = null;
        let oldestTimestamp = new Date().toISOString();
        for (const league of leagues) {
            const metadata = await getMetadata(league.id);
            if (metadata.timestamp < oldestTimestamp) {
                oldestTimestamp = metadata.timestamp;
                oldestKey = league.id;
            }
        }

        // Entferne die Liga mit dem ältesten Zugriff
        if (oldestKey !== null) {
            await deleteLeagueData(oldestKey);
        }
    }

    // Dann füge die neue Liga hinzu
    return new Promise((resolve, reject) => {
        const transaction = db.transaction(["leagues"], "readwrite");
        const store = transaction.objectStore("leagues");
        const dataToStore = {
            id: key,
            players: JSON.stringify(players),
            league_stats: JSON.stringify(league_stats)
        };
        const request = store.put(dataToStore);
        request.onsuccess = () => resolve(request.result);
        request.onerror = (event) => reject(event.target.errorCode);
    });
};

export const deleteLeagueData = (key) => {
    return dbInitPromise.then(() => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["leagues", "metadata"], "readwrite");
            const leaguesStore = transaction.objectStore("leagues");
            const metadataStore = transaction.objectStore("metadata");

            // Lösche zuerst die Liga-Daten
            const leagueDeleteRequest = leaguesStore.delete(key);
            leagueDeleteRequest.onsuccess = () => {
                // Nach erfolgreichem Löschen der Liga-Daten, lösche die Metadaten
                const metadataDeleteRequest = metadataStore.delete(key);
                metadataDeleteRequest.onsuccess = () => resolve();
                metadataDeleteRequest.onerror = (event) => reject(event.target.errorCode);
            };
            leagueDeleteRequest.onerror = (event) => reject(event.target.errorCode);
        });
    });
};
export const getAllData = () => {
    return dbInitPromise.then(() => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["leagues"], "readonly");
            const store = transaction.objectStore("leagues");
            const request = store.getAll();

            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event.target.errorCode);
        });
    });
};
export const getDataByKey = (key) => {
    return dbInitPromise.then(() => {
        return new Promise((resolve, reject) => {

            const transaction = db.transaction(["leagues"], "readonly");
            const store = transaction.objectStore("leagues");
            const request = store.get(key);
            request.onsuccess = () => {
                if (request.result) {
                    // Angenommen, request.result enthält die Eigenschaften 'league_stats' und 'players'
                    const league_stats = JSON.parse(request.result.league_stats);
                    const players = JSON.parse(request.result.players);

                    // Beide Objekte in einem Objekt zusammenfassen und zurückgeben
                    resolve({
                        league_stats: league_stats,
                        players: players
                    });
                } else {
                    resolve(null); // Gibt `null` zurück, wenn kein Eintrag mit dem gegebenen Key gefunden wurde
                }
            };
            request.onerror = (event) => reject(event.target.errorCode);
        });
    });
};
// Funktion, um die Speichernutzung und -grenzen zu schätzen
export const estimateStorageUsage = () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
        return navigator.storage.estimate();
    } else {
        console.warn('StorageManager API is not available in this browser.');
        return Promise.reject('StorageManager API is not available.');
    }
};

// Funktion zum Hinzufügen eines Favoriten
export const addFavorite = (id, year, leagueID, state, description) => {
    return dbInitPromise.then(() => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["favorites"], "readwrite");
            const store = transaction.objectStore("favorites");

            const favorite = {
                id: id,          // Benutzerdefinierter Key für Favoriten
                year: year,      // Saison
                leagueID: leagueID, // Liga-ID
                state: state,    // Bundesland
                description: description // Beschreibung (z.B. Liganame - Bereich - formattedSeason)
            };

            const request = store.put(favorite);
            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event.target.errorCode);
        });
    });
};

// Funktion zum Abrufen aller Favoriten
export const getFavorites = () => {
    return dbInitPromise.then(() => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["favorites"], "readonly");
            const store = transaction.objectStore("favorites");
            const request = store.getAll();

            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event.target.errorCode);
        });
    });
};

// Funktion zum Löschen eines Favoriten
export const deleteFavorite = (id) => {
    return dbInitPromise.then(() => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(["favorites"], "readwrite");
            const store = transaction.objectStore("favorites");
            const request = store.delete(id);

            request.onsuccess = () => resolve();
            request.onerror = (event) => reject(event.target.errorCode);
        });
    });
};