import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IndexView.vue'),
    meta: {
      title: 'Handball Statistiken'
    }
  },
  {
    path: '/Kontakt',
    name: 'kontakt',
    component: () => import('../views/KontaktView.vue'),
    meta: {
      title: 'Handball Statistiken | Kontakt'
    }
  },
  {
    path: '/Impressum',
    name: 'impressum',
    component: () => import('../views/ImprintView.vue'),
    meta: {
      title: 'Handball Statistiken | Impressum'
    }
  },
  {
    path: '/:bundesland/:season',
    name: 'landing_bl',
    component: () => import('../views/LandingPageBL.vue'),
    meta: {
      title: 'Handball Statistiken | :bundesland :season'
    }
  },
  {
    path: '/:bundesland/:season/:ligaId',
    name: 'liga',
    component: () => import('../views/LeagueView.vue'),
  },
  {
    path: '/:bundesland/:season/Gesamt',
    name: 'bl_overall',
    component: () => import('../views/OverallView.vue'),
  },
  {
    path: '/playerhistory/:bundesland/:query',
    name: 'playerhistory',
    component: () => import('../views/PlayerHistory.vue'),
    meta: {
      layout: 'minimal',
    }
  },
  {
    path: '/Wartung',
    name: 'wartung',
    component: () => import('../views/MaintenanceView.vue'),
  },
]

const router = createRouter({
  // history: createWebHistory('/v2/'),
  // base:'/v2/',
  history: createWebHistory('/'),
  base:'/',
  backendPath:'/backend',
  routes
})

router.onError((error) => {
  console.error('Routing error:', error);
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'wartung' && to.name !== "impressum") {
    next('/Wartung');
  } else {
    next();
  }
});

export default router